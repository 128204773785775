/* eslint-disable @typescript-eslint/naming-convention */ //This file contains types that are used in poc-mfe and can be deleted in the future

export enum TabName {
  Completed = "COMPLETED",
  Pending = "PENDING",
  Null = "null",
}

export enum ScreenName {
  Accounts = "ACCOUNTS",
  AccountDetails = "ACCOUNT_DETAILS",
  AdminUserInvitation = "ADMIN_USER_INVITATION",
  Bulk = "BULK",
  CustomerUserInvitation = "CUSTOMER_USER_INVITATION",
  Users = "USERS",
  Permissions = "PERMISSIONS",
  UserDetails = "USER_DETAILS",
  UsersChart = "USERS_CHART",
  LinkAccount = "LINK_ACCOUNT",
  Null = "null",
}

export enum ScreenName2 {
  Accounts1 = "ACCOUNTS",
  AccountDetails = "ACCOUNT_DETAILS",
  AdminUserInvitation1 = "ADMIN_USER_INVITATION",
  CustomerUserInvitation1 = "CUSTOMER_USER_INVITATION",
  Users2 = "USERS",
  UserDetails2 = "USER_DETAILS",
  UserInvitation1 = "USER_INVITATION",
  Null2 = "null",
}

export enum PurchaseExperience {
  BeesCustomer = "BEES_CUSTOMER",
  BeesLink = "BEES_LINK",
  Regular = "REGULAR",
  SmallFormat = "SMALL_FORMAT",
  KeyAccount = "KEY_ACCOUNT",
  Null = "null",
}

export enum SearchCriteria {
  Email = "EMAIL",
  Id = "ID",
  Name = "NAME",
  Other = "OTHER",
  Phone = "PHONE",
}

export enum WholesalerCountry {
  Ar = "AR",
  Br = "BR",
  Ca = "CA",
  Co = "CO",
  Dr = "DR",
  Ec = "EC",
  Hn = "HN",
  Kr = "KR",
  Mx = "MX",
  Pa = "PA",
  Pe = "PE",
  Py = "PY",
  Sv = "SV",
  Tz = "TZ",
  Ug = "UG",
  Uk = "UK",
  Us = "US",
  Uy = "UY",
  Za = "ZA",
}
export enum WholesalerFilter {
  Branch = "BRANCH",
  Wholesaler = "WHOLESALER",
  Null = "null",
}

export interface ICustomerInviteData {
  vendorAccountId: string;
  accountName: string;
  accountCountry: string;
  newUserEmail: string;
}

export interface IWholesalerData {
  wholesalerCountry?: WholesalerCountry;
  wholesalerFilter?: WholesalerFilter;
}

export interface IUserRemovalSubmittedData {
  vendorAccountId: string;
  accountName: string;
  removedUsersId: string[];
}

export interface IRemoveAdminAccessData {
  removedUserId: string;
  accountCountry: string;
  vendorAccountId: string;
  accountName: string;
}

export interface IAccountDetailsData {
  vendorAccountId: string;
  purchaseExperience: string;
  accountName: string;
  associatedUsers: number;
  changeSettingsIsEnabled: boolean;
  addNewUserButtonIsEnabled: boolean;
}

export interface IPurchaseExperienceSubmitted {
  accountName: string;
  vendorAccountId: string;
  purchaseExperience: string;
  associatedUsers: number;
}
export interface IAccountClicked {
  listedItems?: number;
  pageNumber: number;
}

export interface IAccountListViewed {
  listedItems: number;
  wholesalerFilter: string;
}

export interface IAccountListSearched {
  searchCriteria: string;
  purchaseExperience: string;
}

export interface IAddAdminAccessData {
  addedUserId: string;
  accountCountry: string;
  vendorAccountId: string;
  accountName: string;
}

export interface ILinkAccountData {
  fileCountry: string;
  fileId: string;
  fileStatus: string;
  fileType: string;
}
