import {
  IUserRemovalSubmittedData,
  IAccountClicked,
  IAccountDetailsData,
  IAccountListSearched,
  IAccountListViewed,
  ICustomerInviteData,
  IRemoveAdminAccessData,
  IWholesalerData,
  ScreenName,
  WholesalerCountry,
  WholesalerFilter,
  PurchaseExperience,
  IPurchaseExperienceSubmitted,
  SearchCriteria,
  IAddAdminAccessData,
  ILinkAccountData,
} from "./types";

import segmentAnalytics from "../../../shared/analytics";

export class AnalyticService {
  static validateValueType(value: string): SearchCriteria {
    const regexId = /^[0-9]+$/;
    const regexName = /^[A-Za-zÀ-ú.\s]+$/;

    if (regexId.test(value)) {
      return SearchCriteria.Id;
    }
    if (regexName.test(value)) {
      return SearchCriteria.Name;
    }

    return SearchCriteria.Other;
  }

  static resendCostumerInvitation = ({
    accountCountry,
    vendorAccountId,
    accountName,
    newUserEmail,
  }: ICustomerInviteData): void => {
    segmentAnalytics.resendCustomerInvitation({
      screen_name: ScreenName.AccountDetails,
      account_name: accountName,
      account_id: vendorAccountId,
      account_country: accountCountry,
      new_user_email: newUserEmail,
    });
  };

  static accountListViewed({
    listedItems,
    wholesalerFilter,
  }: IAccountListViewed): void {
    segmentAnalytics.accountListViewed({
      screen_name: ScreenName.Accounts,
      listed_items: listedItems,
      wholesaler_filter: wholesalerFilter as WholesalerFilter,
      search_criteria: SearchCriteria.Other,
      account_information: null,
    });
  }

  static accountListSearched({
    searchCriteria,
    purchaseExperience,
  }: IAccountListSearched): void {
    segmentAnalytics.accountListSearched({
      screen_name: ScreenName.Accounts,
      search_criteria: this.validateValueType(searchCriteria),
      purchase_experience: purchaseExperience as PurchaseExperience,
    });
  }

  static wholesalerFiltered({
    wholesalerCountry,
    wholesalerFilter: wholesalerFilterValue,
  }: IWholesalerData): void {
    segmentAnalytics.wholesalerFiltered({
      screen_name: ScreenName.Accounts,
      wholesaler_country: wholesalerCountry ?? ("" as WholesalerCountry),
      wholesaler_filter: wholesalerFilterValue ?? WholesalerFilter.Null,
    });
  }

  static accountClicked({ listedItems, pageNumber }: IAccountClicked): void {
    segmentAnalytics.accountClicked({
      screen_name: ScreenName.Accounts,
      listed_items: listedItems ?? null,
      page_number: pageNumber,
    });
  }

  static copyCostumerRegistrationLink = ({
    accountCountry,
    vendorAccountId,
    accountName,
    newUserEmail,
  }: ICustomerInviteData): void => {
    segmentAnalytics.copyCustomerRegistrationLink({
      screen_name: ScreenName.AccountDetails,
      account_name: accountName,
      account_id: vendorAccountId,
      account_country: accountCountry,
      new_user_email: newUserEmail,
    });
  };

  static exportAccountList = ({
    wholesalerCountry,
    wholesalerFilter,
  }: IWholesalerData): void => {
    segmentAnalytics.exportAccountList({
      screen_name: ScreenName.Accounts,
      wholesaler_country: wholesalerCountry ?? WholesalerCountry.Us,
      wholesaler_filter: wholesalerFilter ?? WholesalerFilter.Null,
    });
  };

  static exportReportDownloaded = ({
    wholesalerCountry,
    wholesalerFilter,
  }: IWholesalerData): void => {
    segmentAnalytics.exportReportDownloaded({
      screen_name: ScreenName.Accounts,
      wholesaler_country: wholesalerCountry ?? WholesalerCountry.Us,
      wholesaler_filter: wholesalerFilter ?? WholesalerFilter.Null,
    });
  };

  static readonly userRemovalSubmitted = ({
    accountName,
    vendorAccountId,
    removedUsersId,
  }: IUserRemovalSubmittedData): void => {
    const removedUsersIds = removedUsersId?.map((userId) => ({
      removed_user_id: userId,
    }));

    segmentAnalytics.userRemovalSubmitted({
      screen_name: ScreenName.AccountDetails,
      account_id: vendorAccountId,
      account_name: accountName,
      removed_user_ids: removedUsersIds,
    });
  };

  static removeAdminAccess = ({
    accountCountry,
    vendorAccountId,
    accountName,
    removedUserId,
  }: IRemoveAdminAccessData): void => {
    segmentAnalytics.removeAdminAccess({
      screen_name: ScreenName.AccountDetails,
      removed_user_ids: [{ removed_user_id: removedUserId }],
      account_country: accountCountry,
      account_id: vendorAccountId,
      account_name: accountName,
    });
  };

  static accountDetailsViewed = ({
    vendorAccountId,
    purchaseExperience,
    accountName,
    associatedUsers,
    addNewUserButtonIsEnabled,
    changeSettingsIsEnabled,
  }: IAccountDetailsData): void => {
    segmentAnalytics.accountDetailsViewed({
      screen_name: ScreenName.AccountDetails,
      account_id: vendorAccountId,
      account_name: accountName,
      associated_users: associatedUsers,
      change_settings_permission: changeSettingsIsEnabled,
      invite_user_permission: addNewUserButtonIsEnabled,
      purchase_experience: purchaseExperience as PurchaseExperience,
    });
  };

  static changePurchaseExperienceStarted = (): void => {
    segmentAnalytics.changePurchaseExperienceStarted({
      screen_name: ScreenName.AccountDetails,
    });
  };

  static purchaseExperienceSubmitted = ({
    vendorAccountId,
    purchaseExperience,
    accountName,
    associatedUsers,
  }: IPurchaseExperienceSubmitted): void => {
    segmentAnalytics.purchaseExperienceSubmitted({
      screen_name: ScreenName.AccountDetails,
      account_name: accountName,
      account_id: vendorAccountId,
      purchase_experience: purchaseExperience as PurchaseExperience,
      associated_users: associatedUsers,
    });
  };

  static addNewUserStarted = (): void => {
    segmentAnalytics.addNewUserStarted({
      screen_name: ScreenName.AccountDetails,
    });
  };

  static readonly addAdminAccess = ({
    accountCountry,
    vendorAccountId,
    accountName,
    addedUserId,
  }: IAddAdminAccessData): void => {
    segmentAnalytics.addAdminAccess({
      screen_name: ScreenName.AccountDetails,
      added_user_ids: [{ added_user_id: addedUserId }],
      account_country: accountCountry,
      account_id: vendorAccountId,
      account_name: accountName,
    });
  };

  static readonly accountLinkingValidationStarted = ({
    fileCountry,
    fileId,
    fileStatus,
    fileType,
  }: ILinkAccountData): void => {
    segmentAnalytics.accountLinkingValidationStarted({
      screen_name: ScreenName.LinkAccount,
      file_country: fileCountry,
      file_id: fileId,
      file_status: fileStatus,
      file_type: fileType,
    });
  };

  static readonly accountLinkingExecutionStarted = ({
    fileCountry,
    fileId,
    fileStatus,
    fileType,
  }: ILinkAccountData): void => {
    segmentAnalytics.accountLinkingExecutionStarted({
      screen_name: ScreenName.LinkAccount,
      file_country: fileCountry,
      file_id: fileId,
      file_status: fileStatus,
      file_type: fileType,
    });
  };
}
